import React from "react";
import { Link, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { COLLAPSED_DRAWER, FIXED_DRAWER } from "constants/ActionTypes";
import { toggleCollapsedNav } from "actions/Setting";
import { defaults } from "configs/CustomColors";
import { userSignOut } from 'actions/Auth';
import { MdExitToApp } from 'react-icons/md';
import { Tooltip } from "@material-ui/core";

const Index = (props) => {

  const dispatch = useDispatch();
  const { navCollapsed } = useSelector(({ common }) => common);
  const { drawerType } = useSelector(({ settings }) => settings);
  const { allowed } = useSelector(({ auth }) => auth);

  const onToggleCollapsedNav = (e) => {
    const val = !navCollapsed;
    dispatch(toggleCollapsedNav(val));
  };

  console.log("ALLOWED", allowed);

  const drawerStyle = drawerType.includes(FIXED_DRAWER) ? "d-block d-xl-none" : drawerType.includes(COLLAPSED_DRAWER) ? "d-block" : "d-none";

  return (
    <AppBar className="app-main-header" style={{ backgroundColor: defaults }}>
      <Toolbar className="app-toolbar" disableGutters={false}>

        <IconButton className={`jr-menu-icon mr-3 ${drawerStyle}`} aria-label="Menu"
          onClick={onToggleCollapsedNav}>
          <span className="menu-icon" />
        </IconButton>

        <Link className="app-logo mr-2 d-none d-sm-block" to="/app/vendas/area">
          <img src={require("assets/images/logo_menor_fundo_escuro .png")} alt="Jambo" title="Jambo" style={{ height: '50px', width: '110px' }} />
        </Link>
        <div style={{ position: "absolute", right: 100, top: 45, fontWeight: "bold", color: "red" }}>
          {!allowed && "Pagamento pendente. Empresa vencida"}
        </div>
        <div style={{ position: 'absolute', right: 50, top: 27 }}>
          {sessionStorage.getItem("@Emissor/nomeEmpresa")}
        </div>

        <Tooltip title="Sair" >
          <div style={{ position: 'absolute', right: 20, top: 25 }} onClick={async () => {
            sessionStorage.clear();
            dispatch(userSignOut());
            window.location.href = '/';
          }}>
            <MdExitToApp size={25} style={{ cursor: 'pointer' }} />
          </div>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};


export default withRouter(Index);
